import {
  Calendar,
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  EventInput,
  EventSourceFuncArg,
} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import { ResourceLabelContentArg } from '@fullcalendar/resource/index.js';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { Controller } from '@hotwired/stimulus';
import { FrameElement } from '@hotwired/turbo';

import { DaysChangeEvent, ScheduleAssignment, ScheduleAssignmentUpdatedEvent } from '../types/app';

export default class ScheduleActivityController extends Controller<HTMLElement> {
  calendar: Calendar | undefined;

  static values = {
    startTime: String,
    endTime: String,
    editable: { type: Boolean, default: false },
    season: Number,
    activityId: Number,
  };

  declare startTimeValue: string;
  declare endTimeValue: string;
  declare editableValue: boolean;
  declare seasonValue: number;
  declare activityIdValue: number;

  connect() {
    this.calendar = new Calendar(this.element, {
      height: '100%',
      expandRows: true,
      plugins: [interactionPlugin, resourceTimeGridPlugin],
      initialView: 'timeGridScheduler',
      headerToolbar: { left: '', center: '', right: '' },
      businessHours: {
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        startTime: this.startTimeValue,
        endTime: this.endTimeValue,
      },
      initialDate: '2000-01-01',
      resourceOrder: 'weekName,dayOrder',
      resources: [],
      selectable: this.editableValue,
      selectConstraint: 'businessHours',
      selectMirror: true,
      views: {
        timeGridScheduler: {
          type: 'resourceTimeGridDay',
          allDaySlot: false,
          slotMinTime: '09:00:00',
          slotMaxTime: '16:30:00',
          slotDuration: '00:15:00',
          displayEventTime: false,
          displayEventEnd: false,
        },
      },
      resourceLabelContent: this.resourceLabelContent.bind(this),
      eventContent: this.eventContent.bind(this),
      select: this.select.bind(this),
      eventClick: this.eventClick.bind(this),
      events: this.fetchEvents.bind(this),
    });
    this.calendar.render();
  }

  disconnect() {
    this.calendar?.destroy();
  }

  resourceLabelContent(argument: ResourceLabelContentArg) {
    const weekElement = document.createElement('div');
    weekElement.textContent = argument.resource.extendedProps.weekName as string;
    const dayElement = document.createElement('div');
    dayElement.textContent = argument.resource.extendedProps.dayName as string;

    return { domNodes: [weekElement, dayElement] };
  }

  eventContent(argument: EventContentArg) {
    return { html: argument.event.title };
  }

  async fetchEvents(
    _info: EventSourceFuncArg,
    successCallback: (eventInputs: EventInput[]) => void,
    failureCallback: (error: Error) => void,
  ) {
    if (!this.calendar || this.calendar.getResources().length === 0) {
      return successCallback([]);
    }

    const url = this.eventSourceURL;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        failureCallback(new Error(response.statusText));
      }

      const json = (await response.json()) as ScheduleAssignment[];

      const eventInputs = json.map((assignment) => {
        return this.parseScheduleAssignment(assignment);
      });

      return successCallback(eventInputs);
    } catch (error: unknown) {
      failureCallback(error as Error);
    }

    return successCallback([]);
  }

  eventName(assignment: ScheduleAssignment) {
    const components = assignment.activity_schedule_display_name ?? [];

    if (components.length === 0) {
      return 'event';
    }

    if (components.length === 1) {
      return components[0];
    }

    return `<div class="two-lines">${components.join('<br>')}</div>`;
  }

  eventTextColor(color: string) {
    const r = Number.parseInt(color.slice(1, 3), 16);
    const g = Number.parseInt(color.slice(3, 5), 16);
    const b = Number.parseInt(color.slice(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }

  parseScheduleAssignment(assignment: ScheduleAssignment): EventInput {
    if (assignment.schedule_activity_id !== this.activityIdValue) {
      return {};
    }

    const color = assignment.division_color ?? assignment.color ?? '#DDDDDD';

    return {
      id: assignment.id.toString(),
      resourceId: assignment.schedule_day_id.toString(),
      start: `${assignment.start_time}`,
      end: `${assignment.end_time}`,
      title: this.eventName(assignment),
      backgroundColor: color,
      borderColor: color,
      textColor: this.eventTextColor(color),
    };
  }

  get eventSourceURL() {
    const url = new URL('/scheduling/schedule_assignments.json', window.location.origin);
    url.searchParams.append('season_id', this.seasonValue.toString());
    url.searchParams.append('schedule_activity_id', this.activityIdValue.toString());

    if (this.calendar) {
      for (const resource of this.calendar.getResources()) {
        url.searchParams.append('schedule_day_ids[]', resource.id);
      }
    }

    return url.href;
  }

  assignmentCreated(info: ScheduleAssignmentUpdatedEvent) {
    const rawEvent = JSON.parse(info.detail.content) as ScheduleAssignment;

    this.calendar?.addEvent(this.parseScheduleAssignment(rawEvent), true);
  }

  assignmentUpdated(info: ScheduleAssignmentUpdatedEvent) {
    const rawEvent = JSON.parse(info.detail.content) as ScheduleAssignment;

    this.calendar?.getEventById(rawEvent.id.toString())?.remove();
    this.calendar?.addEvent(this.parseScheduleAssignment(rawEvent), true);
  }

  assignmentDestroyed(info: ScheduleAssignmentUpdatedEvent) {
    const { id } = JSON.parse(info.detail.content) as { id: number };

    this.calendar?.getEventById(id.toString())?.remove();
  }

  daysChanged(event: DaysChangeEvent) {
    if (!this.calendar) return;

    for (const resource of this.calendar.getResources()) {
      resource.remove();
    }

    for (const day of event.detail.days) {
      this.calendar.addResource(day);
    }

    this.calendar.refetchEvents();
  }

  select(info: DateSelectArg) {
    if (!this.#modalTurboFrame) return;
    if (!info.resource) return;

    const url = new URL('/scheduling/schedule_assignments/new', window.location.origin);
    url.searchParams.append('type', 'activity');
    url.searchParams.append('season_id', this.seasonValue.toString());
    url.searchParams.append('schedule_day_id', info.resource.id);
    url.searchParams.append('schedule_activity_id', this.activityIdValue.toString());
    url.searchParams.append('start_time', info.start.toTimeString().slice(0, 8));
    url.searchParams.append('end_time', info.end.toTimeString().slice(0, 8));

    this.#modalTurboFrame.src = url.href;

    this.calendar?.unselect();
  }

  eventClick(info: EventClickArg) {
    if (!this.editableValue) return;
    if (!this.#modalTurboFrame) return;

    const url = new URL(`/scheduling/schedule_assignments/${info.event.id}/edit`, window.location.origin);
    url.searchParams.append('type', 'activity');

    this.#modalTurboFrame.src = url.href;
  }

  get #modalTurboFrame() {
    return document.querySelector<FrameElement>('turbo-frame[id="modal"]');
  }
}
