import { Controller } from '@hotwired/stimulus';

import { leave } from './helpers/transitions';

export default class DelayedRemoveController extends Controller<HTMLElement> {
  static values = {
    time: { type: Number, default: 8 }, // in seconds
  };

  declare timeValue: number;

  timeout: ReturnType<typeof setTimeout> | undefined;

  connect() {
    if (this.timeValue === 0) {
      return;
    }

    this.timeout = setTimeout(() => {
      this.remove();
    }, this.#transformedTimeValue);
  }

  disconnect() {
    clearTimeout(this.timeout);

    this.remove();
  }

  remove() {
    void leave(this.element).then(() => {
      this.element.remove();
    });
  }

  // private

  get #transformedTimeValue() {
    return this.timeValue * 1000;
  }
}
