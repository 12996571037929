import { Controller } from '@hotwired/stimulus';

export default class TransportationSummaryController extends Controller {
  static targets = ['form'];

  declare readonly formTarget: HTMLFormElement;

  seasonChange() {
    this.formTarget.requestSubmit();
  }

  displayChange() {
    this.formTarget.requestSubmit();
  }
}
