import { Controller } from '@hotwired/stimulus';

export default class ProjectionsController extends Controller {
  static targets = ['selectedList', 'selectedButtonText', 'buttonIcon'];

  declare readonly selectedListTarget: HTMLDivElement;
  declare readonly selectedButtonTextTarget: HTMLSpanElement;
  declare readonly buttonIconTarget: HTMLSpanElement;

  connect() {
    this.selectedChange();
  }

  selectedChange() {
    const checked = this.selectedListTarget.querySelectorAll('input[type="checkbox"]:checked');
    this.selectedButtonTextTarget.textContent = checked.length === 1 ? '1 Year' : `${checked.length} Years`;
  }

  submit() {
    this.buttonIconTarget.classList.add('animate-spin');
  }
}
