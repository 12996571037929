import { computePosition, offset, flip, shift, Placement } from '@floating-ui/dom';
import { Controller } from '@hotwired/stimulus';

import { enter, leave } from './helpers/transitions.ts';

export default class DropdownController extends Controller {
  static targets = ['button', 'menu'];
  static values = {
    open: { type: Boolean, default: false },
    key: { type: String, default: 'Escape' },
    position: { type: String, default: 'bottom-end' },
    offset: { type: Number, default: 2 },
  };

  declare readonly buttonTarget: HTMLElement;
  declare readonly menuTarget: HTMLElement;
  declare openValue: boolean;
  declare keyValue: string;
  declare positionValue: Placement;
  declare offsetValue: number;

  disconnect() {
    this.openValue = false;
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  hide(event: Event) {
    if (!this.openValue) return;

    if (this.element.contains(event.target as Node) === false) {
      this.openValue = false;
    }
  }

  hideWithKey(event: KeyboardEvent) {
    if (!this.openValue) return;

    if (event.key === this.keyValue) {
      this.openValue = false;
    }
  }

  // private

  openValueChanged() {
    if (this.openValue) {
      this.#showMenu();

      this.#computePosition();
    } else {
      this.#hideMenu();
    }
  }

  #showMenu() {
    void enter(this.menuTarget);
  }

  #hideMenu() {
    void leave(this.menuTarget);
  }

  #computePosition() {
    computePosition(this.buttonTarget, this.menuTarget, {
      placement: this.positionValue,
      middleware: [offset(this.offsetValue), flip(), shift({ padding: 3 })],
    })
      .then(({ x, y }) => {
        Object.assign(this.menuTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      })
      .catch((error: unknown) => {
        console.error(error);
      });
  }
}
