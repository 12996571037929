import { Controller } from '@hotwired/stimulus';

export default class DataAttributeController extends Controller {
  static values = { key: String };

  declare keyValue: string;

  disconnect() {
    this.element.removeAttribute(this.keyValue);
  }

  toggle() {
    this.element.toggleAttribute(`data-${this.keyValue}`);
  }
}
