// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export function debounce(this: unknown, callback: Function, delay: number) {
  let timeout: ReturnType<typeof setTimeout>;

  return (...arguments_: never) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      callback.apply(this, arguments_);
    }, delay);
  };
}
