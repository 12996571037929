export class Template {
  message: string;
  cancelLabel: string;
  confirmLabel: string;

  constructor({ message = 'Are you sure you want to continue?', cancelLabel = 'Cancel', confirmLabel = 'Confirm' }) {
    this.message = message;
    this.cancelLabel = cancelLabel;
    this.confirmLabel = confirmLabel;
  }

  static styleConfig = {
    container: 'flex items-center justify-center fixed inset-0 w-full h-full z-200 bg-gray-600/40',
    modal: 'w-full max-w-md bg-white rounded-lg shadow-xl ring-1 ring-offset-0 ring-gray-200',
    content: 'p-4 md:p-5 text-center',
    text: 'mb-5 text-lg font-normal text-gray-500 dark:text-gray-400',
    button: 'py-2.5 px-5 text-sm font-medium rounded-lg',
    confirmButton:
      'text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800  ',
    cancelButton:
      'ms-3 text-gray-900 focus:outline-none bg-white  border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100',
  };

  render() {
    return `
      <div id="confirm-modal" class="${this.#getClass('container')}">
        <div class="${this.#getClass('modal')}">
          <div class="${this.#getClass('content')}">
            <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
            </svg>
            <h3 class="${this.#getClass('text')}">
              ${this.message}
            </h3>

            <button data-behavior="commit" class="${this.#getClass('button')} ${this.#getClass('confirmButton')}">
              ${this.confirmLabel}
            </button>

            <button data-behavior="cancel" class="${this.#getClass('button')} ${this.#getClass('cancelButton')}">
              ${this.cancelLabel}
            </button>
          </div>
        </div>
      </div>
    `;
  }

  // private

  #getClass(element: keyof typeof Template.styleConfig) {
    return Template.styleConfig[element];
  }
}
