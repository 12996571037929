import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';
import mapboxgl, { LngLatBounds, Map, Marker, NavigationControl, Popup } from 'mapbox-gl';

import { createMarker, popupOffset } from '../utilities/marker.ts';

export default class NearbyLocationsController extends Controller {
  static targets = ['form', 'map', 'list'];
  static values = { latitude: String, longitude: String, formattedAddress: String, apiKey: String };

  declare latitudeValue: string;
  declare longitudeValue: string;
  declare formattedAddressValue: string;
  declare apiKeyValue: string;
  declare readonly formTarget: HTMLFormElement;
  declare readonly mapTarget: HTMLElement;
  declare readonly listTarget: HTMLElement;

  map: Map | undefined;
  markers: Record<string, Marker> = {};

  connect() {
    this.initializeMap();
    this.loadMarkers();
  }

  disconnect() {
    if (this.markers) this.removeAllMarkers();
    if (this.map) this.map.remove();
  }

  listTargetConnected() {
    if (this.map) this.loadMarkers();
  }

  initializeMap() {
    mapboxgl.accessToken = this.apiKeyValue;

    this.map = new Map({
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: this.mapCenter(),
      zoom: 12,
    });

    this.map.addControl(new NavigationControl(), 'top-right');
  }

  showLocation(event: Event) {
    this.hideAllPopups();

    const location = event.currentTarget;
    if (!location || !(location instanceof HTMLElement)) return;

    const id = location.dataset.id ?? '';
    const marker = this.markers[id];

    if (!marker || !this.map) return;

    this.map.flyTo({ center: marker.getLngLat(), zoom: 14 });
    marker.togglePopup();
  }

  mapCenter(): [number, number] {
    const list = this.listTarget;
    return [Number.parseFloat(list.dataset.longitude ?? ''), Number.parseFloat(list.dataset.latitude ?? '')];
  }

  seasonChange(event: Event) {
    if (!(event.target instanceof HTMLSelectElement)) return;

    const season = event.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set('season_id', season);
    Turbo.visit(url.toString());
  }

  styleChange(event: Event) {
    if (!(event.target instanceof HTMLSelectElement)) return;

    const style = event.target.value;

    if (this.map && style) {
      this.map.setStyle(style);
    }
  }

  reloadMap() {
    this.loadMarkers();
  }

  fitBoundsToMarkers() {
    const markers = Object.values(this.markers);

    if (!this.map) return;

    if (markers.length === 0) {
      this.map.setCenter(this.mapCenter());
      this.map.setZoom(12);
      return;
    }

    const bounds = new LngLatBounds();
    for (const marker of Object.values(this.markers)) {
      bounds.extend(marker.getLngLat());
    }
    this.map.fitBounds(bounds, { padding: 50 });
  }

  hideAllPopups() {
    for (const marker of Object.values(this.markers)) {
      const popup = marker.getPopup();
      if (popup?.isOpen()) {
        popup.remove();
      }
    }
  }

  addMarker(location: HTMLElement) {
    const id = location.id;
    const latitude = Number.parseFloat(location.dataset.latitude ?? '');
    const longitude = Number.parseFloat(location.dataset.longitude ?? '');
    const color = location.dataset.color ?? 'blue';
    const icon = location.dataset.icon ?? 'circle';
    const contents = location.innerHTML;

    if (!this.map) return;

    const popup = new Popup({ maxWidth: 'none', offset: popupOffset() }).setHTML(contents);
    popup.on('open', () => {
      location.classList.add('bg-blue-100');
      location.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
    popup.on('close', () => {
      location.classList.remove('bg-blue-100');
    });

    const markerElement = createMarker(color, icon);
    const marker = new Marker({ element: markerElement, offset: [0, -14] }).setLngLat([longitude, latitude]);
    marker.setPopup(popup);
    marker.addTo(this.map);
    this.markers[id] = marker;
  }

  removeAllMarkers() {
    for (const marker of Object.values(this.markers)) {
      marker.remove();
    }
    this.markers = {};
  }

  loadMarkers() {
    this.removeAllMarkers();

    if (!this.map) return;

    if (this.listTarget.dataset['formatted-address']) {
      const marker = new Marker({ color: 'red' }).setLngLat(this.mapCenter());
      marker.addTo(this.map);
      this.markers.center = marker;
    }

    const locations = this.listTarget.querySelectorAll<HTMLDivElement>(':scope > div');

    for (const location of locations) {
      this.addMarker(location);
    }

    this.fitBoundsToMarkers();
  }
}
