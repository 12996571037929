import { Controller } from '@hotwired/stimulus';
import { addYears, parseISO, formatISO } from 'date-fns';

export default class TransactionSummaryController extends Controller {
  static targets = ['form', 'seasonSelect', 'transactionsBefore', 'compareToSelect'];

  declare readonly formTarget: HTMLFormElement;
  declare readonly seasonSelectTarget: HTMLSelectElement;
  declare readonly transactionsBeforeTarget: HTMLInputElement;
  declare readonly compareToSelectTarget: HTMLSelectElement;

  currentSeason: number | undefined;

  connect() {
    this.currentSeason = Number.parseInt(this.seasonSelectTarget.value);
  }

  submit() {
    this.formTarget.requestSubmit();
  }

  transactionsBeforeChange() {
    this.formTarget.requestSubmit();
  }

  compareToSeasonChange() {
    this.formTarget.requestSubmit();
  }

  seasonChange() {
    const yearDiff = Number.parseInt(this.seasonSelectTarget.value) - (this.currentSeason ?? 0);
    const transactionsBefore = parseISO(this.transactionsBeforeTarget.value);
    const adjustedDate = addYears(transactionsBefore, yearDiff);

    this.compareToSelectTarget.value = '';
    this.transactionsBeforeTarget.value = formatISO(adjustedDate, { representation: 'date' });
    this.currentSeason = Number.parseInt(this.seasonSelectTarget.value);

    this.formTarget.requestSubmit();
  }
}
