import { Controller } from '@hotwired/stimulus';
import type { FrameElement } from '@hotwired/turbo';
import Chartkick from 'chartkick';

export default class ChartsCountsController extends Controller {
  static targets = ['form', 'chart'];

  declare formTarget: HTMLFormElement;
  declare chartTarget: HTMLElement;

  connect() {
    window.addEventListener('chartkick:load', this.chartTargetConnected.bind(this), { once: true });
  }

  chartTargetConnected() {
    const chart = Chartkick.charts['counts-chart'];

    if (chart) {
      const chartConfig = chart.getChartObject();
      chartConfig.update({
        plotOptions: {
          series: {
            point: {
              events: {
                click: this.chartClicked.bind(this),
              },
            },
          },
        },
      });
    }
  }

  chartClicked(event: Highcharts.PointClickEventObject) {
    const category = event.point.category as string;
    const series = event.point.series.name;
    this.loadModal(category, series);
  }

  loadModal(category: string, series: string) {
    const formData = new FormData(this.formTarget);
    formData.set('category', category);
    formData.set('series', series);

    const queryString = new URLSearchParams(formData as unknown as Record<string, string>).toString();
    const url = `${this.formTarget.action}/modal?${queryString}`;

    const frame = document.querySelector<FrameElement>('turbo-frame#modal');
    if (frame) {
      frame.src = url;
    }
  }

  submit() {
    this.formTarget.requestSubmit();
  }
}
