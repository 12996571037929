import { Context, Controller } from '@hotwired/stimulus';
import type { TurboBeforeFetchResponseEvent, FrameElement } from '@hotwired/turbo';
import { saveAs } from 'file-saver';

import parseContentDisposition from '../utilities/parse-content-disposition';

export default class ReportController extends Controller {
  static targets = ['groupList'];

  declare readonly groupListTarget: FrameElement;

  turboFormSubmitEndHandler: (event: TurboBeforeFetchResponseEvent) => void;
  disableSubmitButtonHandler: () => void;
  enableSubmitButtonHandler: () => void;

  constructor(context: Context) {
    super(context);
    this.turboFormSubmitEndHandler = this.turboFormSubmitEnd.bind(this);
    this.disableSubmitButtonHandler = this.disableSubmitButton.bind(this);
    this.enableSubmitButtonHandler = this.enableSubmitButton.bind(this);
  }

  connect() {
    this.element.addEventListener('turbo:before-fetch-response', this.turboFormSubmitEndHandler);
    this.element.addEventListener('turbo:submit-start', this.disableSubmitButtonHandler);
    this.element.addEventListener('turbo:submit-end', this.enableSubmitButtonHandler);
  }

  disconnect() {
    this.element.removeEventListener('turbo:before-fetch-response', this.turboFormSubmitEndHandler);
    this.element.removeEventListener('turbo:submit-start', this.disableSubmitButtonHandler);
    this.element.removeEventListener('turbo:submit-end', this.enableSubmitButtonHandler);
  }

  turboFormSubmitEnd(event: TurboBeforeFetchResponseEvent) {
    const response = event.detail.fetchResponse.response;

    if (response.ok) {
      const contentType = response.headers.get('Content-Type');
      const filename = parseContentDisposition(response.headers.get('Content-Disposition'));

      if (contentType === 'application/pdf') {
        event.preventDefault();

        response
          .blob()
          .then((blob) => {
            saveAs(blob, filename ?? 'report.pdf', { autoBom: true });
          })
          .catch((error) => {
            console.error('Failed to save PDF file:', error);
          });
      }
    }
  }

  disableSubmitButton() {
    const submitButton = this.element.querySelector<HTMLInputElement>('input[type="submit"]');

    if (submitButton) {
      submitButton.disabled = true;
      submitButton.classList.add('bg-gray-700', 'cursor-not-allowed');
      submitButton.classList.remove('bg-blue-700', 'hover:bg-blue-800');
    }
  }

  enableSubmitButton() {
    const submitButton = this.element.querySelector<HTMLInputElement>('input[type="submit"]');

    if (submitButton) {
      submitButton.disabled = false;
      submitButton.classList.remove('bg-gray-700', 'cursor-not-allowed');
      submitButton.classList.add('bg-blue-700', 'hover:bg-blue-800');
    }
  }

  seasonChanged(event: Event) {
    if (!(event.target instanceof HTMLSelectElement)) return;

    const seasonFieldName = event.target.name;
    const seasonId = event.target.value;

    const url = new URL(window.location.href);
    url.searchParams.set(seasonFieldName, seasonId);

    const frame = this.groupListTarget;
    frame.src = url.toString();
  }

  selectAllGroups() {
    const frame = this.groupListTarget;
    for (const checkbox of frame.querySelectorAll<HTMLInputElement>('input[type="checkbox"]')) {
      checkbox.checked = true;
    }
  }

  selectNoGroups() {
    const frame = this.groupListTarget;
    for (const checkbox of frame.querySelectorAll<HTMLInputElement>('input[type="checkbox"]')) {
      checkbox.checked = false;
    }
  }
}
