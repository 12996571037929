function addClasses(element: HTMLElement, classes: string[]) {
  element.classList.add(...classes);
}

function removeClasses(element: HTMLElement, classes: string[]) {
  element.classList.remove(...classes);
}

function nextFrame() {
  return new Promise((resolve) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve);
    });
  });
}

function afterTransition(element: HTMLElement) {
  return Promise.all(element.getAnimations().map((animation) => animation.finished.catch(() => null)));
}

async function transition(direction: string, element: HTMLElement, animation: string | null) {
  const dataset = element.dataset;
  const animationClass = animation ? `${animation}-${direction}` : direction;

  const transition = `transition${direction.charAt(0).toUpperCase() + direction.slice(1)}`;

  const genesis = dataset[transition] ? dataset[transition].split(' ') : [animationClass];
  const datasetTransitionStart = dataset[`${transition}Start`];
  const start = datasetTransitionStart ? datasetTransitionStart.split(' ') : [`${animationClass}-start`];
  const datasetTransitionEnd = dataset[`${transition}End`];
  const end = datasetTransitionEnd ? datasetTransitionEnd.split(' ') : [`${animationClass}-end`];

  addClasses(element, genesis);
  addClasses(element, start);

  await nextFrame();

  removeClasses(element, start);
  addClasses(element, end);

  await afterTransition(element);

  removeClasses(element, end);
  removeClasses(element, genesis);
}

export async function enter(element: HTMLElement, transitionName = null) {
  element.removeAttribute('hidden');

  await transition('enter', element, transitionName);
}

export async function leave(element: HTMLElement, transitionName = null) {
  await transition('leave', element, transitionName);

  element.setAttribute('hidden', 'true');
}
