import { Controller } from '@hotwired/stimulus';
import Pickr from '@simonwep/pickr';

export default class ColorPickerController extends Controller<HTMLElement> {
  declare readonly inputTarget: HTMLInputElement;
  declare readonly buttonTarget: HTMLButtonElement;
  declare themeValue: Pickr.Theme;

  picker: Pickr | undefined;

  static targets = ['button', 'input'];

  static values = {
    theme: {
      type: String,
      default: 'classic',
    },
  };

  initialize() {
    this.onSave = this.onSave.bind(this);
  }

  connect() {
    this.picker = Pickr.create({
      el: this.buttonTarget,
      theme: this.themeValue,
      default: this.inputTarget.value,
      swatches: this.swatches,
      components: this.componentOptions,
    });

    this.picker.on('save', this.onSave.bind(this));
  }

  disconnect() {
    if (this.picker) {
      this.picker.destroy();
    }
  }

  changed() {
    if (this.picker) {
      if (this.inputTarget.value === '') {
        this.picker.setColor(null);
      } else {
        this.picker.setColor(this.inputTarget.value);
      }
    }
  }

  onSave(color: Pickr.HSVaColor) {
    const originalColor = this.inputTarget.value;

    this.inputTarget.value = '';

    if (color) {
      this.inputTarget.value = color.toHEXA().toString();
    }

    if (originalColor !== this.inputTarget.value) {
      this.inputTarget.dispatchEvent(new Event('input'));
    }

    if (this.picker) {
      this.picker.hide();
    }
  }

  get componentOptions(): object {
    return {
      preview: true,
      hue: true,

      interaction: {
        input: true,
        clear: true,
        save: true,
      },
    };
  }

  get swatches(): string[] {
    return [
      '#A0AEC0',
      '#F56565',
      '#ED8936',
      '#ECC94B',
      '#48BB78',
      '#38B2AC',
      '#4299E1',
      '#667EEA',
      '#9F7AEA',
      '#ED64A6',
    ];
  }
}
