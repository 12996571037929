import { Controller } from '@hotwired/stimulus';

export default class ScheduleDaySelectController extends Controller {
  static targets = ['checkbox'];

  declare readonly checkboxTarget: HTMLInputElement;
  declare readonly checkboxTargets: HTMLInputElement[];

  connect() {
    setTimeout(() => {
      this.selectWeeks();
      this.loadDays();
    }, 10);
  }

  // Initialize the checkboxes based on the selected days
  selectWeeks() {
    for (const checkbox of this.checkboxTargets) {
      if (checkbox.dataset.day === undefined) {
        const weekId = checkbox.dataset.week;

        const checkboxesForWeek = this.checkboxTargets.filter((checkbox) => {
          return checkbox.dataset.week === weekId && checkbox.dataset.day !== undefined;
        });

        const checked = checkboxesForWeek.some((checkbox) => checkbox.checked);
        const unchecked = checkboxesForWeek.some((checkbox) => !checkbox.checked);

        checkbox.checked = checked && !unchecked;
      }
    }
  }

  selectWeek(event: Event) {
    const target = event.target as HTMLInputElement;
    const week = target.dataset.week;

    for (const checkbox of this.checkboxTargets) {
      if (checkbox.dataset.week === week) {
        checkbox.checked = target.checked;
      }
    }

    this.updateDays();
    this.storeSelectedDays();
  }

  selectDay() {
    this.selectWeeks();
    this.updateDays();
    this.storeSelectedDays();
  }

  get selectedDays() {
    return this.checkboxTargets
      .filter((checkbox) => {
        return checkbox.checked && checkbox.dataset.day !== undefined;
      })
      .map((checkbox) => {
        return {
          id: checkbox.dataset.day,
          week: checkbox.dataset.week,
          weekName: checkbox.dataset['week-name'],
          dayName: checkbox.dataset['day-name'],
          dayOrder: checkbox.dataset['day-order'],
        };
      });
  }

  loadDays() {
    this.dispatch('loaded', { detail: { days: this.selectedDays } });
  }

  updateDays() {
    this.dispatch('changed', { detail: { days: this.selectedDays } });
  }

  storeSelectedDays() {
    const selectedDays = this.selectedDays.map((day) => day.id);

    fetch('/scheduling/set_session_days', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.head.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')?.content ?? '',
      },
      body: JSON.stringify({ schedule_day_ids: selectedDays }),
    })
      .then((response) => {
        if (!response.ok) {
          console.error('Failed to store selected days');
        }
      })
      .catch((error) => {
        console.error('Failed to store selected days', error);
      });
  }
}
