import { Controller } from '@hotwired/stimulus';
import { FrameElement, TurboSubmitEndEvent } from '@hotwired/turbo';

// Used by modal component
export default class DialogController extends Controller<HTMLElement> {
  static values = { elementId: String };

  declare elementIdValue: string;

  connect() {
    this.element.focus();
  }

  hide() {
    this.element.remove();

    if (this.#dialogTurboFrame) {
      this.#dialogTurboFrame.src = '';
    }
  }

  hideOnSubmit(event: TurboSubmitEndEvent) {
    if (event.detail.success) {
      this.hide();
    }
  }

  disconnect() {
    if (this.#dialogTurboFrame) {
      this.#dialogTurboFrame.src = '';
    }
  }

  // private

  get #dialogTurboFrame() {
    return document.querySelector<FrameElement>(`turbo-frame[id="${this.elementIdValue}"]`);
  }
}
