import { Context, Controller } from '@hotwired/stimulus';

export default class ScheduleFrequencyChartController extends Controller {
  static targets = ['form'];

  declare readonly formTarget: HTMLFormElement;

  turboFrameDidRenderHandler: () => void;

  constructor(context: Context) {
    super(context);
    this.turboFrameDidRenderHandler = this.turboFrameDidRender.bind(this);
  }

  connect() {
    document.addEventListener('turbo:frame-render', this.turboFrameDidRenderHandler);
  }

  disconnect() {
    document.removeEventListener('turbo:frame-render', this.turboFrameDidRenderHandler);
  }

  // We're doing this because popper.js is not working correctly with Turbo Frames
  // This is a hack to force a reposition of the popper elements
  turboFrameDidRender() {
    window.scrollTo(window.scrollX, window.scrollY - 1);
    window.scrollTo(window.scrollX, window.scrollY + 1);
  }

  daysChanged() {
    this.formTarget.requestSubmit();
  }

  seasonChange() {
    this.formTarget.requestSubmit();
  }
}
