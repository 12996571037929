import { Turbo } from '@hotwired/turbo-rails';

import { Template } from './confirm_modal/template.ts';

// Setup:
// Add this file into your application.js, eg.
// `import "./lib/confirm_modal.js"`
//
// Usage:
// ```ruby
//  data: {
//    turbo_method: "delete",
//    turbo_confirm: "Really delete this filter?",
//    turbo_confirm_confirm_label: "Yes, put it in the shredder!",
//    turbo_confirm_cancel_label: "Oops, no go back…",
//  }
// ```
//
function insertConfirmModal(message: string, element: HTMLElement) {
  const cancelLabel = element.dataset.turboConfirmCancelLabel ?? 'Cancel';
  const confirmLabel = element.dataset.turboConfirmConfirmLabel ?? 'Confirm';

  const template = new Template({
    message: message,
    cancelLabel: cancelLabel,
    confirmLabel: confirmLabel,
  });

  document.body.insertAdjacentHTML('beforeend', template.render());
  (document.activeElement as HTMLElement)?.blur();

  return document.querySelector('#confirm-modal');
}

Turbo.config.forms.confirm = (message: string, element: HTMLElement) => {
  const dialog = insertConfirmModal(message, element);

  if (!dialog) {
    return Promise.resolve(false);
  }

  return new Promise((resolve) => {
    dialog.querySelector("[data-behavior='cancel']")?.addEventListener(
      'click',
      () => {
        dialog.remove();

        resolve(false);
      },
      { once: true },
    );
    dialog.querySelector("[data-behavior='commit']")?.addEventListener(
      'click',
      () => {
        dialog.remove();

        window.dispatchEvent(new CustomEvent('confirm-modal:commit'));

        resolve(true);
      },
      { once: true },
    );
  });
};
