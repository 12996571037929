import { Controller } from '@hotwired/stimulus';

import { debounce } from '../utilities/debounce';

export default class TableController extends Controller<HTMLFormElement> {
  static values = {
    delay: {
      type: Number,
      default: 150,
    },
  };

  declare delayValue: number;

  initialize(): void {
    this.submit = this.submit.bind(this);
  }

  connect(): void {
    if (this.delayValue > 0) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      this.submit = debounce(this.submit, this.delayValue);
    }
  }

  submit() {
    this.element.requestSubmit();
  }
}
