export default function parseContentDisposition(contentDisposition: string | null) {
  if (!contentDisposition) return null;

  const regex = /filename="?([^";]+)"?/;
  const match = regex.exec(contentDisposition);
  if (match) {
    return match[1];
  }
  return null;
}
